export default {
  maxCountOfPaymentMethods: 9,
  maxCountOfShippingAddresses: 5,
  fragrancesAmount: 900,
  productsRemainingLimit: 300,
  defaultCaseDiscountPercent: { en: 38 },
  defaultCasePrice: { en: 1000 },
  defaultCaseRetailPrice: { en: 1595 },
  defaultCasePriceWithDiscount: { en: 1000 },
  defaultDriftPrice: { en: 990 },
  defaultDriftRetailPrice: { en: 1295 },
  defaultCandlePrice: { en: 2500 },
  defaultCandleRetailPrice: { en: 7500 },
  defaultSamplesPrice: { en: 795 },
  defaultSamplesRetailPrice: { en: 990 },
}
